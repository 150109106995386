import React, { Component } from 'react'
import Title from '../components/Title'

export default class Team extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <>
            
      <Title title="Our Team" />
                <section className="team_section text-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="service_section_title">Our Team</h1>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-3'>
                                <div className='single_team mt-4'>
                                    <img className='img-fluid' src='https://digitalit-inst.com/upload/website_teams/2021071320_9.png'></img>
                                    <div className='single_team_footer'>
                                        <h1>Ashraful Islam</h1>
                                        <h4>Customer Relationship Officer</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='single_team mt-4'>
                                    <img className='img-fluid' src='https://digitalit-inst.com/upload/website_teams/2021022052_8.jpg'></img>
                                    <div className='single_team_footer'>
                                        <h1> Md. Maruf Hossain</h1>
                                        <h4>Digital Marketer & Trainer</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='single_team mt-4'>
                                    <img className='img-fluid' src='https://digitalit-inst.com/upload/website_teams/2021022042_5.jpg'></img>
                                    <div className='single_team_footer'>
                                        <h1> ABU BAKER</h1>
                                        <h4>Graphic Designer & Trainer</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='single_team mt-4'>
                                    <img className='img-fluid' src='https://digitalit-inst.com/upload/website_teams/2021022041_6.jpg'></img>
                                    <div className='single_team_footer'>
                                        <h1>  MD. Miraj Mahmud</h1>
                                        <h4>Senior Web Designer & Trainer</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='single_team mt-4'>
                                    <img className='img-fluid' src='https://digitalit-inst.com/upload/website_teams/2021071320_9.png'></img>
                                    <div className='single_team_footer'>
                                        <h1>Ashraful Islam</h1>
                                        <h4>Customer Relationship Officer</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='single_team mt-4'>
                                    <img className='img-fluid' src='https://digitalit-inst.com/upload/website_teams/2021022052_8.jpg'></img>
                                    <div className='single_team_footer'>
                                        <h1> Md. Maruf Hossain</h1>
                                        <h4>Digital Marketer & Trainer</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='single_team mt-4'>
                                    <img className='img-fluid' src='https://digitalit-inst.com/upload/website_teams/2021022042_5.jpg'></img>
                                    <div className='single_team_footer'>
                                        <h1> ABU BAKER</h1>
                                        <h4>Graphic Designer & Trainer</h4>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='single_team mt-4'>
                                    <img className='img-fluid' src='https://digitalit-inst.com/upload/website_teams/2021022041_6.jpg'></img>
                                    <div className='single_team_footer'>
                                        <h1>  MD. Miraj Mahmud</h1>
                                        <h4>Senior Web Designer & Trainer</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

            </>
        )
    }
}
