import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';

export default class Service extends Component {
    render() {
        return (
            <>


                <section className="service_section text-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="service_section_title">Our Services</h1>
                            </div>
                        </div>

                        <div className="row">



                            <div className="col-sm-3 mt-4">
                                <div className="single_service_block">
                                    <span className="">
                                        <a className="c-perple  wow animate__heartBeat" href="">

                                            <FontAwesomeIcon icon={faBookOpen} />

                                        </a>
                                    </span>
                                    <h1>
                                        <a className="c-perple" href=""> Best Tutors</a>
                                    </h1>
                                    <p className="text-justify">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus alias, commodi dolore. Quos veritatis laborum facere quam praesentium cupiditate fugiat
                                    </p>
                                </div>
                            </div>



                            <div className="col-sm-3 mt-4">
                                <div className="single_service_block">
                                    <span className="">
                                        <a className="c-perple  wow animate__heartBeat" href="">

                                        <FontAwesomeIcon icon={faBookOpen} />

                                        </a>
                                    </span>
                                    <h1>
                                        <a className="c-perple" href=""> Intarective  Class </a>
                                    </h1>
                                    <p className="text-justify">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus alias, commodi dolore. Quos veritatis laborum facere quam praesentium cupiditate fugiat
                                    </p>
                                </div>
                            </div>



                            <div className="col-sm-3 mt-4">
                                <div className="single_service_block">
                                    <span className="">
                                        <a className="c-green  wow animate__heartBeat" href="">

                                        <FontAwesomeIcon icon={faBookOpen} />

                                        </a>
                                    </span>
                                    <h1>
                                        <a className="c-green" href="">Flexible Timing </a>
                                    </h1>
                                    <p className="text-justify">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus alias, commodi dolore. Quos veritatis laborum facere quam praesentium cupiditate fugiat
                                    </p>
                                </div>
                            </div>



                            <div className="col-sm-3 mt-4">
                                <div className="single_service_block">
                                    <span className="">
                                        <a className="c-orange  wow animate__heartBeat" href="">

                                        <FontAwesomeIcon icon={faBookOpen} />

                                        </a>
                                    </span>
                                    <h1>
                                        <a className="c-orange" href=""> Any Time &#038; Device </a>
                                    </h1>
                                    <p className="text-justify">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Temporibus alias, commodi dolore. Quos veritatis laborum facere quam praesentium cupiditate fugiat
                                    </p>
                                </div>
                            </div>



                        </div>
                       
                    </div>
                </section>

            </>
        )
    }
}
