import React, { Component } from 'react'
import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';
import Title from '../components/Title';
export default class GalleryPage extends Component {

    render() {
        const IMAGES =
            [
                {
                    src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
                    thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,
                    caption: "After Rain (Jeshu John - designerspics.com)"
                },
                {
                    src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
                    thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
                    thumbnailWidth: 320,
                    thumbnailHeight: 212,
                    tags: [{ value: "Ocean", title: "Ocean" }, { value: "People", title: "People" }],
                    caption: "Boats (Jeshu John - designerspics.com)"
                },

                {
                    src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
                    thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
                    thumbnailWidth: 320,
                    thumbnailHeight: 212
                },
                {
                    src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
                    thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,
                    caption: "After Rain (Jeshu John - designerspics.com)"
                },
                {
                    src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
                    thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
                    thumbnailWidth: 320,
                    thumbnailHeight: 212,
                    tags: [{ value: "Ocean", title: "Ocean" }, { value: "People", title: "People" }],
                    caption: "Boats (Jeshu John - designerspics.com)"
                },

                {
                    src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
                    thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
                    thumbnailWidth: 320,
                    thumbnailHeight: 212
                },

                {
                    src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
                    thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
                    thumbnailWidth: 320,
                    thumbnailHeight: 212
                },
                {
                    src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
                    thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
                    thumbnailWidth: 320,
                    thumbnailHeight: 174,
                    caption: "After Rain (Jeshu John - designerspics.com)"
                },
                {
                    src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
                    thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
                    thumbnailWidth: 320,
                    thumbnailHeight: 212,
                    tags: [{ value: "Ocean", title: "Ocean" }, { value: "People", title: "People" }],
                    caption: "Boats (Jeshu John - designerspics.com)"
                },

                {
                    src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
                    thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
                    thumbnailWidth: 320,
                    thumbnailHeight: 212
                }
            ]

        return (
            <>

                <Title title="Gallery" />
                <section className="course_section text-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="service_section_title">Photo Gallery</h1>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 mt-3'>
                                <Gallery images={IMAGES} />
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
