import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faUser} from '@fortawesome/free-solid-svg-icons';

export default class Hero extends Component {
  render() {
    return (
      <>
    
        <section className="hero_bg_big_text">
            <div className="container pt-4 pb-5" >
                <div className="row">
                    <div className='col-sm-4 mt-4'>
                      <div className='hero_box'>
                        <div className='hero_icon'> <FontAwesomeIcon icon={faPhone} /> </div>
                        <div className='hero_text'> <a href="tel:01737963893">01737963893</a> </div>
                      </div>
                    </div>
                    <div className='col-sm-4 mt-4'>
                      <div className='hero_box'>
                        <div className='hero_icon'> <FontAwesomeIcon icon={faEnvelope} /> </div>
                        <div className='hero_text'> <a href="mailto:example.email@gmail.com">example.email@gmail.com</a> </div>
                      </div>
                    </div>
                    <div className='col-sm-4 mt-4'>
                      <div className='hero_box'>
                        <div className='hero_icon'> <FontAwesomeIcon icon={faUser} /> </div>
                        <div className='hero_text'> <a href="#">24/7 Service</a> </div>
                      </div>
                    </div>
                    
                </div>
            </div>
        </section>
      </>
    )
  }
}
