import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf} from '@fortawesome/free-solid-svg-icons';
import CourseImage1 from '../../Images/Course/data-web.png';
import CourseImage2 from '../../Images/Course/Digital-marketing-web.png';
import CourseImage3 from '../../Images/Course/Graphic-design-web.png';
import CourseImage4 from '../../Images/Course/office-Application.png';
import CourseImage5 from '../../Images/Course/web-design-web.png';
import CourseImage6 from '../../Images/Course/web-development.png';

export default class Course extends Component {
    render() {
        return (
            <>


                <section className="course_section text-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="service_section_title">Our Courses</h1>
                            </div>
                        </div>

                        <div className="row">

                            <div className='col-sm-4'>
                                <div className='single_course mt-5 text-sm-start'>
                                    <div className='course_image'>
                                        <img className='img-fluid' src={CourseImage1} />
                                    </div>
                                    <div className='course_footer'>
                                        <h3>Class:8 (2HR) Weekly 2 Days</h3>
                                        <h1>Data Entry & Freelancing</h1>

                                        
                                    </div>

                                    <div className='row pb-3'>
                                        <div className='col-6'>
                                            <ul className='ratting'>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStarHalf} /></li>
                                            </ul>
                                        </div>
                                        <div className='col-6'> <p className='course_fee'>Course Fee: <span>20,000TK</span></p> </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='single_course mt-5 text-sm-start'>
                                    <div className='course_image'>
                                        <img className='img-fluid' src={CourseImage2} />
                                    </div>
                                    <div className='course_footer'>
                                        <h3>Class:8 (2HR) Weekly 2 Days</h3>
                                        <h1>Digital Marketing & Freelancing</h1>

                                        
                                    </div>

                                    <div className='row pb-3'>
                                        <div className='col-6'>
                                            <ul className='ratting'>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStarHalf} /></li>
                                            </ul>
                                        </div>
                                        <div className='col-6'> <p className='course_fee'>Course Fee: <span>20,000TK</span></p> </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='single_course mt-5 text-sm-start'>
                                    <div className='course_image'>
                                        <img className='img-fluid' src={CourseImage3} />
                                    </div>
                                    <div className='course_footer'>
                                        <h3>Class:8 (2HR) Weekly 2 Days</h3>
                                        <h1>Graphic Design & Freelancing</h1>

                                        
                                    </div>

                                    <div className='row pb-3'>
                                        <div className='col-6'>
                                            <ul className='ratting'>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStarHalf} /></li>
                                            </ul>
                                        </div>
                                        <div className='col-6'> <p className='course_fee'>Course Fee: <span>20,000TK</span></p> </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='single_course mt-5 text-sm-start'>
                                    <div className='course_image'>
                                        <img className='img-fluid' src={CourseImage4} />
                                    </div>
                                    <div className='course_footer'>
                                        <h3>Class:8 (2HR) Weekly 2 Days</h3>
                                        <h1>Office Application</h1>

                                        
                                    </div>

                                    <div className='row pb-3'>
                                        <div className='col-6'>
                                            <ul className='ratting'>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStarHalf} /></li>
                                            </ul>
                                        </div>
                                        <div className='col-6'> <p className='course_fee'>Course Fee: <span>20,000TK</span></p> </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='single_course mt-5 text-sm-start'>
                                    <div className='course_image'>
                                        <img className='img-fluid' src={CourseImage5} />
                                    </div>
                                    <div className='course_footer'>
                                        <h3>Class:8 (2HR) Weekly 2 Days</h3>
                                        <h1>Web Design & Freelancing</h1>

                                        
                                    </div>

                                    <div className='row pb-3'>
                                        <div className='col-6'>
                                            <ul className='ratting'>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStarHalf} /></li>
                                            </ul>
                                        </div>
                                        <div className='col-6'> <p className='course_fee'>Course Fee: <span>20,000TK</span></p> </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-sm-4'>
                                <div className='single_course mt-5 text-sm-start'>
                                    <div className='course_image'>
                                        <img className='img-fluid' src={CourseImage6} />
                                    </div>
                                    <div className='course_footer'>
                                        <h3>Class:8 (2HR) Weekly 2 Days</h3>
                                        <h1>Web Development & Freelancing</h1>
                                    </div>

                                    <div className='row pb-3'>
                                        <div className='col-6'>
                                            <ul className='ratting'>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStarHalf} /></li>
                                            </ul>
                                        </div>
                                        <div className='col-6'> <p className='course_fee'>Course Fee: <span>20,000TK</span></p> </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                       
                    </div>
                </section>

            </>
        )
    }
}
