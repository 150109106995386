import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel2';
import '../../lib/owl.carousel.css';
import '../../lib/owl.theme.default.css';
export default class Carosel extends Component {

    render() {
        const options = {
            items: 1,
            nav: true,
            rewind: true,
            autoplay: true,
            margin:10,
            autoplay:true,
            dots:false
        };

        const events = {
            onDragged: function (event) { },
            onChanged: function (event) { }
        };

        return (
            <>
                <section className='carosel_section'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-12 no-padding-col'>
                                <div className='owlTemplate'>
                                    <OwlCarousel ref="car" options={options} events={events} >
                                        <div  className='item'>
                                            <img src="https://cdn.pixabay.com/photo/2017/10/24/10/30/business-2884023_960_720.jpg" alt="images not found" />
                                            <div className="cover">
                                                <div className="container">
                                                    <div className="header-content">
                                                        <div className="line"></div>
                                                        <h2>Teimagine Digital Experience with</h2>
                                                        <h1>Start-ups and solutions</h1>
                                                        <h4>We help entrepreneurs, start-ups and enterprises shape their ideas into products</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className='item'>
                                            <img src="https://cdn.pixabay.com/photo/2017/10/24/10/30/business-2884023_960_720.jpg" alt="images not found" />
                                            <div className="cover">
                                                <div className="container">
                                                    <div className="header-content">
                                                        <div className="line"></div>
                                                        <h2>Teimagine Digital Experience with</h2>
                                                        <h1>Start-ups and solutions</h1>
                                                        <h4>We help entrepreneurs, start-ups and enterprises shape their ideas into products</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className='item'>
                                            <img src="https://cdn.pixabay.com/photo/2017/10/24/10/30/business-2884023_960_720.jpg" alt="images not found" />
                                            <div className="cover">
                                                <div className="container">
                                                    <div className="header-content">
                                                        <div className="line"></div>
                                                        <h2>Teimagine Digital Experience with</h2>
                                                        <h1>Start-ups and solutions</h1>
                                                        <h4>We help entrepreneurs, start-ups and enterprises shape their ideas into products</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div  className='item'>
                                            <img src="https://cdn.pixabay.com/photo/2017/10/24/10/30/business-2884023_960_720.jpg" alt="images not found" />
                                            <div className="cover">
                                                <div className="container">
                                                    <div className="header-content">
                                                        <div className="line"></div>
                                                        <h2>Teimagine Digital Experience with</h2>
                                                        <h1>Start-ups and solutions</h1>
                                                        <h4>We help entrepreneurs, start-ups and enterprises shape their ideas into products</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
