import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <>
 <section className="about_section pt-5">
        <div className="container">
            <div className="row">
                <div className="col-sm-6 text-center text-sm-start">
                    <div className="about_content">
                       <h6> About us </h6>
                        <h1 className="wow animate__bounceIn">Village It Center</h1>
                        
                        <div className="about_description text-left py-2 wow animate__fadeInDown">
                            <p>We are Providing Online Holy Quran Classes All Over The World.Specially Learn Quran Online Bd is looking for Bangladeshi who are residing abroad. Let's learn Quran Online with one of the best Online Quran Learning Academy. We are providing online Quran teaching service to kids and adults, male and female across the globe. Our Quran learning courses are specially designed for you and your kids. Under the guidance of qualified Quran Tutors, we will provide you step by step Quran Learning with the rules of Tajweed and essential Islamic knowledge. Are you looking for an online Quran tutor for yourself or for your children, let’s learn Quran with online Quran tutor in one-to-one Quran class at the comfort of your home.</p>                        </div>
                        
                    </div>
                </div>
                
                <div className="col-sm-6">
                    <img src="https://cdn.shopify.com/s/files/1/0110/8810/1439/files/og__dgg2o9b9b2wm_600x600.png?v=1592581253" alt="" className="img-fluid wow animate__fadeInRight" />
                </div>
            </div>
        </div>
    </section>
    
      </>
    )
  }
}
