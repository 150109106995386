import React, { Component } from 'react'
import Hero from '../components/Home/Hero';
import Title from '../components/Title';

export default class Contact extends Component {
    render() {
        return (
            <>
                <Title title="Contact Us" />

                <section className="course_section py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h1 className="service_section_title">Have Some Questions ?</h1>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6 mt-5'>
                                <form>
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                        <div className="mb-3">
                                            <label  className="form-label">Full Name</label>
                                            <input type="text" className="form-control" placeholder='Full  Name'/>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control" placeholder='Email'/>
                                        </div>
                                        <div className="mb-3">
                                            <label  className="form-label">Phone</label>
                                            <input type="text" className="form-control" placeholder='Phone'/>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Message</label>
                                            <textarea  className="form-control" placeholder='Message'></textarea>
                                        </div>

                                        <button type="submit" className="btn btn-primary red_button">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className='col-sm-6 mt-5'>
                            <div className="google-map-code">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15282225.79979123!2d73.7250245393691!3d20.750301298393563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1587818542745!5m2!1sen!2sin" width="600" height="450"  ></iframe>
        </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Hero />
            </>
        )
    }
}
