import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Notfound from './Pages/Notfound';
import Home from './Pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import CoursesPage from './Pages/CoursesPage';
import Team from './Pages/Team';
import GalleryPage from './Pages/GalleryPage';
import Contact from './Pages/Contact';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/courses">
            <CoursesPage />
          </Route>
          <Route exact path="/team">
            <Team />
          </Route>
          <Route exact path="/gallery">
            <GalleryPage />
          </Route>
          <Route exact path="/contact">
            <Contact />
          </Route>
          <Route>
            <Notfound />
          </Route>

        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
