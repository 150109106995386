import React, { Component } from 'react'
import Course from '../components/Home/Course'
import Title from '../components/Title';

export default class CoursesPage extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
      <Title title="Our Courses" />
        <Course />
      </>
    )
  }
}
