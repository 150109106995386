import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Logo from '../Images/logo.png';
import { NavLink } from "react-router-dom";

import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';


export default class Header extends Component {
    render() {
        return (
            <>



                <section className="header_top_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 py-1 text-center text-sm-start">
                                <p className="header_top_text mb-0"> <span> <FontAwesomeIcon icon={faPhone} /></span> +8801778854554 </p>
                            </div>
                            <div className="col-sm-6 py-1 text-center text-sm-end">
                                <p className="header_top_text mb-0"> <span><FontAwesomeIcon icon={faEnvelope} /></span>  info@learnquranonlinebd.com </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='header_main_menu'>

                    <Navbar bg="light" className="navbar navbar-expand-lg navbar-light bg-light top-nav" expand="lg">
                        <Container>
                            <NavLink className="navbar-brand top-nav-brand animate__animated animate__heartBeat" to="/"><img src={Logo} alt="" className="img-fluid" /></NavLink>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav>
                                    <Nav.Item>
                                        <NavLink className='nav-link' exact to="/">Home</NavLink>
                                    </Nav.Item>
                                    
                                    <Nav.Item>
                                        <NavLink className='nav-link' to="/courses">Courses</NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink className='nav-link' to="/team">Team</NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink className='nav-link' to="/gallery">Gallery</NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink className='nav-link' to="/feedback">Feedback</NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink  className='nav-link'to="/contact">Contact</NavLink>
                                    </Nav.Item>

                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>

                </section>

            </>
        )
    }
}
